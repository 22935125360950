export default {
    policy: {
        zhCN: {
            policy_no: '政策号',
            policy_name: '政策名称',
            rebate_type: '返利类型',
            start_date: '开始日期',
            end_date: '结束日期',
            policy_status: '政策状态',
            create_time: '创建时间',
            action: '操作',
            vehicle_material_code: '车辆物料号',
            material_description: '材料描述',
            no: '序号',
            bonus: '补贴金额',
            terms: '返还比例',
            bothNone: '补贴金额和返还比例不能同时为空',
            basic_margin_bothNone: '基础返利和基础返利比例不能同时为空',
            codeNeed: '物料号不能为空',
            versionNo: '版本号',
        },
        enUS: {
            policy_no: 'Policy No.',
            policy_name: 'Policy Name',
            rebate_type: 'Rebate Type',
            start_date: 'Start Date',
            end_date: 'End Date',
            policy_status: 'Policy Status',
            create_time: 'Create Time',
            action: 'Action',
            vehicle_material_code: 'Vehicle Material Code',
            material_description: 'Material Description',
            no: 'No.',
            bonus: 'Bonus',
            terms: 'Terms',
            bothNone: 'Bonus and Terms cannot be empty at the same time',
            basic_margin_bothNone:
                'Basic Margin and Basic Margin Terms cannot be empty at the same time',
            codeNeed: 'Please input Vehicle Material Code',
            versionNo: 'Version No.',
        },
    },
    credit: {
        zhCN: {
            number_order: '序号',
            credit_company_code: '信贷机构编码',
            credit_company_name: '信贷机构名称',
            contact_person: '联系人',
            contact_number: '联系电话',
            status: '状态',
            e_mail: '邮箱',
            reconciliation_cycle: '对账周期',
        },
        enUS: {
            number_order: 'No.',
            credit_company_code: 'Credit Company Code',
            credit_company_name: 'Credit Company Name',
            contact_person: 'Contact Person',
            contact_number: 'Contact Number',
            status: 'Status',
            e_mail: 'Email',
            reconciliation_cycle: 'Reconciliation Cycle',
        },
    },
    fleetType: {
        zhCN: {
            number_order: '序号',
            rebate_type_code: '返利类型代码',
            rebate_type_name: '返利类型名称',
            operation: '操作',
            validity_period: '有效期',
            current_validity_period: '当前有效期',
            area: '区域',
            new_rebate_type: '新增返利类型',
            model: '车型',
            version: '版本',
            bonus: '补贴额度',
            terms: '返利比例',
        },
        enUS: {
            number_order: 'NO.',
            rebate_type_code: 'Rebate Type Code',
            rebate_type_name: 'Rebate Type Name',
            operation: 'Operation',
            validity_period: 'Validity Period',
            current_validity_period: 'Current Validity Period',
            area: 'Area',
            new_rebate_type: 'New Rebate Type',
            model: 'Model',
            version: 'Version',
            bonus: 'Bonus',
            terms: 'Terms',
        },
    },
    fleet: {
        zhCN: {
            number_order: '序号',
            fleet_code: '大客户编号',
            fleet_name: '大客户名称',
            fleet_rebate_type: '大客户返利类型',
            area: '区域',
            contacts: '联系人',
            operation: '操作',
            TEL: '联系电话',
            new_fleet: '新增大客户',
            maximum_number_of_car_purchases: '购车上限数量',
            car_purchase_time_limit: '购车时效',
            minimum_number_of_car_purchases: '购车下限数量',
            notes: '备注',
            model: '车型',
            version: '版本',
            bonus: '补贴额度',
            terms: '返利比例',
        },
        enUS: {
            number_order: 'NO.',
            fleet_code: 'Fleet Code',
            fleet_name: 'Fleet Name',
            fleet_rebate_type: 'Fleet Rebate Type',
            area: 'Area',
            contacts: 'Contacts',
            operation: 'Operation',
            TEL: 'TEL',
            new_fleet: 'New Fleet',
            maximum_number_of_car_purchases: 'Maximum Number Of Car Purchases',
            car_purchase_time_limit: 'Car Purchase Time Limit',
            minimum_number_of_car_purchases: 'Minimum Number Of Car Purchases',
            notes: 'Notes',
            model: 'Model',
            version: 'Version',
            bonus: 'Bonus',
            terms: 'Terms',
        },
    },
    KPI: {
        zhCN: {
            number_order: '序号',
            kpi_policy_code: 'KPI政策编号',
            kpi_policy_name: 'KPI政策名称',
            area: '区域',
            start_time: '开始时间',
            end_time: '结束时间',
            operation: '操作',
            sales_types_involved_in_calculating_target_achievement: '参与计算目标达成的销售类型',
            sales_types_involved_in_calculating_rebates: '参与计算返利的销售类型',
            describe: 'KPI描述',
            new_kpi_rebate_standard_settings: '新增KPI返利标准',
            set_level: '设置级别',
            vehicle_model_rebate_maintenance: '车型返利维护',
            level_name: '级别名称',
            level: '级别',
            model: '车型',
            terms: '返利比例',
        },
        enUS: {
            number_order: 'NO.',
            kpi_policy_code: 'KPI Policy Code',
            kpi_policy_name: 'Quarterly Target Name',
            area: 'Area',
            start_time: 'Start Time',
            end_time: 'End Time',
            operation: 'Operation',
            sales_types_involved_in_calculating_target_achievement:
                'Sales Types Involved In Calculating Target Achievement',
            sales_types_involved_in_calculating_rebates:
                'Sales Types Involved In Calculating Rebates',
            describe: 'Describe',
            new_kpi_rebate_standard_settings: 'New KPI Rebate Standard Settings',
            set_level: 'Set Level',
            vehicle_model_rebate_maintenance: 'Vehicle Model Rebate Maintenance',
            level_name: 'Level Name',
            level: 'Level',
            model: 'Model',
            terms: 'Terms',
        },
    },
    price: {
        zhCN: {
            copy_price_term: '复制价格条款',
            copy_policy_no: '复制政策',
            incremental: '增量式',
            full: '覆盖式',
            origin_price_term: '原价格条款',
            type: '类型',
            add_more: '添加更多',
            series: '车系',
            gear: '变速箱',
            trimversion: '内饰',
            price_term_code: '价格条款代码',
            price_term_name: '价格条款名称',
            order_type: '订单类型',
            dealer_code: '经销商代码',
            price_term_channel: '渠道',
            rebate_type: '返利类型',
            basic_margin: '基础返利',
            basic_margin_terms: '基础返利比例',
            vehicle_material_code: '车型物料号',
            material_description: '物料描述',
            bonus: '返利金额',
            terms: '返利比例',
            term: '比例',
            version_no: '版本号',
            update_by: '更新依据',
            update_time: '更新时间',
            detail: '详情',
            receiv_time: '发布时间',
            tip_vehicle: '输入的物料号重复',
            paint_price: '返还喷漆价格',
            basic_margin_refund_paint: 'Basic Margin Refund Paint',
            is_basic_price_plus_paint: '(Basic Price + Paint) * X%',
            coupons_amount_net: '券面额（Net）',
            dealer_margin: '经销商返利',
            dealer_refund: '经销商退款',
            dealer_margin_bonus: '经销商返利金额',
            dealer_refund_bonus: '经销商退款金额',
            dealer_margin_terms: '经销商返利比例',
            dealer_refund_terms: '经销商退款比例',
            approval_user: '审批人',
            approval_time: '审批时间',
            submission_time: '提交时间',
            price_term_type: 'Price Term Type',
            effective_date: '生效日期',
        },
        enUS: {
            copy_price_term: 'Copy Price Term',
            copy_policy_no: 'Copy Policy No.',
            incremental: 'Incremental',
            full: 'Full',
            origin_price_term: 'Origin Price Term',
            type: 'Type',
            add_more: 'Add More',
            series: 'Series',
            gear: 'Gear',
            trimversion: 'Trimversion',
            price_term_code: 'Price Term Code',
            price_term_name: 'Price Term Name',
            order_type: 'Order Type',
            dealer_code: 'Dealer Code',
            price_term_channel: 'Price Term Channel',
            rebate_type: 'Rebate Type',
            basic_margin: 'Basic Margin',
            basic_margin_terms: 'Basic Margin Terms',
            vehicle_material_code: 'Vehicle Material Code',
            material_description: 'Material Description',
            bonus: 'Bonus',
            terms: 'Terms',
            term: 'Terms',
            version_no: 'Version No.',
            update_by: 'Update By',
            update_time: 'Update Time',
            detail: 'Detail',
            receiv_time: 'Receive Time',
            tip_vehicle: 'The entered Vehicle Material Code is duplicate',
            paint_price: 'Refund Paint',
            basic_margin_refund_paint: 'Basic Margin Refund Paint',
            is_basic_price_plus_paint: '(Basic Price + Paint) * X%',
            coupons_amount_net: 'Test Drive Voucher',
            dealer_margin: 'Dealer Margin',
            dealer_refund: 'Dealer Refund',
            dealer_margin_bonus: 'Dealer Margin Bonus',
            dealer_refund_bonus: 'Dealer Refund Bonus',
            dealer_margin_terms: 'Dealer Margin Terms',
            dealer_refund_terms: 'Dealer Refund Terms',
            approval_user: 'Approver',
            approval_time: 'Approval Time',
            approved_remark: 'Approved Remark',
            submission_time: 'Submission Time',
            price_term_type: 'Price Term Type',
            effective_date: 'Effective Date',
        },
    },
    kpi: {
        zhCN: {
            area: '地区',
            kpi_policy_no: 'KPI政策编号',
            kpi_policy_name: 'KPI政策名称',
            rebate_type: '返利类型',
            settlement_cycle: '结算周期',
            credit_company: '信贷公司',
            start_date: '开始时间',
            end_date: '结束时间',
            date: '时间',
            quarter: '季度',
            month: '月份',
            create_time: '创建时间',
            policy_status: '政策状态',
            set_vehicle_model_rebate: '设置车型折扣',
            set_level: '设置级别',
            types_of_orders_involved_in_calculating_target_achievement:
                '计算目标实现所涉及的订单类型',
            Order_types_involved_in_calculating_rebates: '计算回扣所涉及的订单类型',
            level_name: '级别名称',
            level_setting: '级别设置',
            level: '级别',
            series: '系别',
            vin: 'VIN',
            bonus: '奖金',
            restricted_order_type: '返利订单类型',
            restricted_series: 'Restricted Series',
            file_name: '文件名称',
            operater: '操作者',
            upload_time: '更新时间',
            version: '版本号',
            petrol_ev: '油车电车必选一个, 或者仅选择车系',
            refund_paint: '返还喷漆费',
            target_type: '目标类型',
        },
        enUS: {
            area: 'Area',
            kpi_policy_no: 'KPI Policy No.',
            kpi_policy_name: 'Quarterly Target Name',
            rebate_type: 'Rebate Type',
            settlement_cycle: 'Settlement Cycle',
            credit_company: 'Credit Company',
            start_date: 'Start Date',
            end_date: 'End Date',
            date: 'Date',
            quarter: 'Quarter',
            month: 'Month',
            create_time: 'Create Time',
            policy_status: 'Policy Status',
            set_vehicle_model_rebate: 'Set Vehicle Model Rebate',
            set_level: 'Set Level',
            types_of_orders_involved_in_calculating_target_achievement:
                'Types of orders involved in calculating target achievement',
            Order_types_involved_in_calculating_rebates:
                'Order types involved in calculating rebates',
            level_name: 'Level Name',
            level_setting: 'Level Setting',
            level: 'Level',
            series: 'Series',
            vin: 'VIN',
            bonus: 'Bonus',
            restricted_order_type: 'Restricted order type',
            restricted_series: 'Restricted Series',
            file_name: 'File Name',
            operater: 'Operater',
            upload_time: 'Upload Time',
            version: 'Version',
            petrol_ev: 'Petrol and EV must be one, or just select the series',
            refund_paint: 'Refund Paint',
            target_type: 'Target Type',
        },
    },
    setting: {
        zhCN: {
            promotion_policy_no: '促销政策编号',
            promotion_policy_name: '促销政策名称',
            customize_column_name: '自定义列名',
            bonus_or_term: 'Bonus or Term',
        },
        enUS: {
            promotion_policy_no: 'Promotion Policy No.',
            promotion_policy_name: 'Promotion Policy Name',
            customize_column_name: 'Customize Column Name',
            bonus_or_term: 'Bonus or Term',
        },
    },
    policyRule: {
        zhCN: {
            policy_rule_no: '政策规则号',
            policy_rule_name: '政策规则名称',
            order_type: '订单类型',
            rebate_type: '返利类型',
            receive_time: '发布时间',
            action: '操作',
            remark: 'Remark',
            create_time: '创建时间',
            operator: '操作人',

            calculation_date: '计算日期',
            validity_date: '有效时间',
            settlement_cycle: '结算周期',

            connector: '操作符',

            version_no: '版本号',
            update_by: '更新依据',
            update_time: '更新时间',
            detail: '详情',
            receiv_time: '发布时间',
            no: '序号',
        },
        enUS: {
            policy_rule_no: 'Policy Rule Code',
            policy_rule_name: 'Policy Rule Name',
            order_type: 'Order Type',
            rebate_type: 'Rebate Type',
            receive_time: 'Receive Time',
            action: 'Action',
            remark: 'Remark',
            create_time: 'Create Time',
            operator: 'Operator',

            version_no: 'Version No.',
            update_by: 'Update By',
            update_time: 'Update Time',
            detail: 'Detail',

            calculation_date: 'Calculation Date',
            validity_date: 'Validity Date',
            settlement_cycle: 'Settlement Cycle',

            connector: 'Connector',

            start_date: 'Start Date',
            end_date: 'End Date',
            policy_status: 'Policy Status',
            no: 'No.',
        },
    },
    parameterSetting: {
        zhCN: {
            parameter_code: '参数号',
            parameter_name: '参数名称',
            parameter_type: '参数类型',
            parameter_table: '表格',
            parent_parameter: '父参数',
            previous_parameter: 'Previous Parameter',
            next_parameter: '子参数',
            related_parameter: 'Related Parameter',
            module_name: '模块名称',
            column_sort: '列排序',
            create_time: '创建时间',
            action: '操作',
            date_type: '日期类型',
            copy: '复制',
            trim_version: 'Trim Version',
            model: '车型',
            term: 'Term',
            origin_special_parameter: 'Origin Special Parameter',
            sp_tip: '导入数据与当前数据存在重复数据',
            sp_copy_tip: '原有数据与当前数据存在重复数据',
        },
        enUS: {
            parameter_code: 'Parameter Code',
            parameter_name: 'Parameter Name',
            parameter_type: 'Parameter Type',
            parent_parameter: 'Parent Parameter',
            previous_parameter: 'Previous Parameter',
            next_parameter: 'Next Parameter',
            related_parameter: 'Related Parameter',
            module_name: 'Module Name',
            parameter_table: 'Table',
            column_sort: 'Column Sort',
            create_time: 'Create Time',
            action: 'Action',
            date_type: 'Date Type',
            copy: 'Copy',
            trim_version: 'Trim Version',
            model: 'Model',
            term: 'Term',
            origin_special_parameter: 'Origin Special Parameter',
            sp_tip: 'There is duplicate data between the imported data and the current data.',
            sp_copy_tip: 'There is duplicate data between the origin data and the current data.',
        },
    },
    policyPortfolio: {
        zhCN: {
            combination_code: '组合码',
            combination_name: '组合名',
            content: '内容',
            calculation_date_retail: 'Calculation Date for Retail',
            content_sales: 'Content for Sales',
            calculation_wholesales: 'Calculation Date for Wholesales',
            content_wholesales: 'Content for Wholesales',
            calculation_car: 'Calculation Date for Used Car',
            content_car: 'Content for Used car',
            customer: '用户',
            create_time: '创建时间',
            action: '操作',
            calculation_date: '计算日期',
            validity_date: '有效时间',
            financial_company: '金融公司',
            settlement_cycle: '结算周期',
            sales_order_type: '销售订单类型',
            policy_combination: '政策类型',
            advance_payment: '预付金额',
            priority: '优先级',
            priority_note: '注：数字越大优先级越高',
            date_note1: '选择Monthly，则开始日期和结束日期只能相差一个月',
            date_note2: '选择Quarter，则开始日期的月份只能选择1，4，7，10，结束日期只能选择3，6，9，12',
            date_note3: 'content for sales/content for wholesales/content for used car 至少选择一个',
            portfolio_code: '组合码',
            portfolio_name: '组合名',
            portfolio_code_f: 'Portfolio 1 Code',
            portfolio_name_f: 'Portfolio 1 Name',
            portfolio_code_s: 'Portfolio 2 Code',
            portfolio_name_s: 'Portfolio 2 Name',
            status: '有效状态',
            exclusive_policy: '此投资组合中可以添加其他政策',
            has_paid: '已支付',
            date_note4: 'Customer/Financial Company 至少选择一个',
            approval_status: '审批状态',
            app_result: '审批结果',
            app_opinion: '审批意见',
            approver: '审批人',
            app_time: '审批时间',
            financial_accrual_No: 'Financial Accrual No.',
            policy_document: 'Policy Document',
            batch_pass_msg: '确认批量通过？',
            portfolio_approval: 'Policy Portfolio Approval',
            portfolio_exclusivity_tip: 'Portfolio 1 Code 和 Portfolio 2 Code 不允许同时选择同一参数值',
            currency: '币种',
            exchange_rate: '汇率',
        },
        enUS: {
            combination_code: 'Portfolio Code',
            combination_name: 'Portfolio Name',
            approval_status: 'Approval Status',
            content: 'Content',
            calculation_date_retail: 'Calculation Date for Retail',
            content_sales: 'Content for Sales',
            calculation_wholesales: 'Calculation Date for Wholesales',
            content_wholesales: 'Content for Wholesales',
            calculation_car: 'Calculation Date for Used Car',
            content_car: 'Content for Used car',
            customer: 'Customer',
            create_time: 'Create Time',
            action: 'Action',
            calculation_date: 'Calculation Date',
            validity_date: 'Validity Date',
            financial_company: 'Financial Company',
            settlement_cycle: 'Settlement Cycle',
            sales_order_type: 'Sales Order Type',
            policy_combination: 'Portfolio type',
            advance_payment: 'Advance Payment',
            priority: 'Priority',
            priority_note: 'Note: The higher the number, the higher the priority',
            date_note1: 'If you choose Monthly, the start date and end date can only differ by one month',
            date_note2: 'If you choose Quarter, the start date month can only be selected as 1, 4, 7, or 10, and the end date can only be selected as 3, 6, 9, or 12',
            date_note3: 'Select at least one piece of content for sales/content for wholesales/content for used car',
            portfolio_code: 'Portfolio Code',
            portfolio_name: 'Portfolio Name',
            portfolio_code_f: 'Portfolio 1 Code',
            portfolio_name_f: 'Portfolio 1 Name',
            portfolio_code_s: 'Portfolio 2 Code',
            portfolio_name_s: 'Portfolio 2 Name',
            status: 'Valid',
            exclusive_policy: 'Other Policy can be added in this portfolio',
            has_paid: 'Paid',
            date_note4: 'customer or financial company is required.',
            app_result: 'Approval Result    ',
            app_opinion: 'Approval Opinion',
            approver: 'Approver',
            app_time: 'Approval Time',
            financial_accrual_No: 'Financial Accrual No.',
            policy_document: 'Policy Document',
            batch_pass_msg: 'Confirm batch pass？',
            portfolio_approval: 'Policy Portfolio Approval',
            portfolio_exclusivity_tip: 'Portfolio 1 Code and Portfolio 2 Code are not allowed to select the same parameter value at the same time',
            currency: 'Currency',
            exchange_rate: 'Exchange Rate',
        },
    },
    groupSetting: {
        zhCN: {
            policy_rule_code: '政策规则号',
            group_type: '组类型',
            group_code: '组编号',
            group_entry: '组编号',
            vehicle_model: '车型',
            trim: '装饰',
        },
        enUS: {
            policy_rule_code: 'Policy Rule Code',
            group_type: 'Group Type',
            group_code: 'Group Code',
            group_entry: 'Group Entry',
            vehicle_model: 'Vehicle Model',
            trim: 'trim',
        },
    },
};
