import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import page_system from './pages/system/index';
import page_basic from './pages/basic-data/index';
import page_upload from './pages/upload/index';
import page_parameter from './pages/parameter/index';
import page_result from './pages/result/index';
import common from './common/index';
import page_report from './pages/main/report';
import page_policy from './pages/policy/index';
export default {
    license: {
        powered_by: 'Powered by SMIL',
        // switch_country: 'Switch Country',
        switch_country: 'Switch Company',
    },
    auth: common.auth.enUS.auth,
    button: common.button.enUS,
    radio: common.table.enUS.radio,
    dropdown: common.table.enUS.dropdown,
    table: common.table.enUS.table,
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/page`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} records in total`,
    },
    confirm: common.auth.enUS.confirm,
    request: common.auth.enUS.request,
    page: {
        global: {
            page_search: 'Page Search',
        },
        error: {
            not_found: 'Not found',
            not_found_des: 'There is nothing here',
            auth_failed: 'Auth Failed',
            auth_failed_des: 'This page is not reachable',
        },
        homepage: {
            homepage: homepage.homepage.enUS,
        },
        main: {
            report: {
                rebate: page_report.rebate.enUS,
                statistics: page_report.statistics.enUS,
                santander: page_report.santander.enUS,
                salesTarget: page_report.salesTarget.enUS,
            },
        },
        system: {
            menu: page_system.menu.enUS,
            dict: page_system.dict.enUS,
            dept: page_system.dept.enUS,
            role: page_system.role.enUS,
            user: page_system.user.enUS,
        },
        basic: {
            dealer: page_basic.dealer.enUS,
            material: page_basic.material.enUS,
            systemParameter: page_basic.systemParameter.enUS,
            modelCategory: page_basic.modelCategory.enUS,
            exchangeRate: page_basic.exchangeRate.enUS,
        },
        upload: {
            coupons: page_upload.coupons.enUS,
            salesTarget: page_upload.salesTarget.enUS,
            nonCompliantDealers: page_upload.nonCompliantDealers.enUS,
            rebateList: page_upload.rebateList.enUS,
            basicPrice: page_upload.basicPrice.enUS,
            vehicleModelStandard: page_upload.vehicleModelStandard.enUS,
            salesVolumeSetting: page_upload.salesVolumeSetting.enUS,
            financialCompany: page_upload.financialCompany.enUS,
            nonCompliance: page_upload.nonCompliance.enUS,
            deductionMgmt: page_upload.deductionMgmt.enUS,
        },
        parameter: {
            credit: page_parameter.credit.enUS,
            price: page_parameter.price.enUS,
            kpi: page_parameter.kpi.enUS,
            policy: page_parameter.policy.enUS,
            setting: page_parameter.setting.enUS,
            policyRule: page_parameter.policyRule.enUS,
            parameterSetting: page_parameter.parameterSetting.enUS,
            policyPortfolio: page_parameter.policyPortfolio.enUS,
            groupSetting: page_parameter.groupSetting.enUS,
        },
        result: {
            salesOrder: page_result.salesOrder.enUS,
            rrmgmt: page_result.rrmgmt.enUS,
            rrapproval: page_result.rrapproval.enUS,
        },
        document: homepage.document.enUS,
        policy: {
            financialAccrual: page_policy.financialAccrual.enUS,
            wholesalesList: page_policy.wholesalesList.enUS,
        },
    },
    validation: {
        common: common.validation.enUS.common,
        tip: common.validation.enUS.tip,
    },
    import: {
        incorrect_file_type: 'Incorrect file type',
        exceed: 'Exceed',
        upload_success: 'Upload success',
        upload_fail: 'Upload fail',
        again_upload:
            'Only image files in jpg, png, bmp, or gif formats can be uploaded. Please upload again',
    },
    export: {
        noDataToExport: 'No data to export',
    },
    select: {
        all: 'All',
        input: 'please input',
        p_input: 'Input',
        select: 'please select',
    },
    checkbox: {
        select_all: 'Select All',
        y: 'Y',
        n: 'N',
    },
    dialog: {
        error_message: 'Error message',
        accessory_selection: 'Part selection',
        noData: 'No data',
    },
    tip: {
        the_start: 'Line',
        the_end: ', mandatory items are not filled in. Please delete if not needed!',
        the_intervals: ', The set value is duplicated with other intervals!',
        error_tip: 'Enter at least one complete piece of data！',
        item_is_required: 'Item is required',
        adjustment: 'No modifications or adjustments made, no need to save',
        select_tip: 'Select at least one piece of data',
        select_error_tip: 'There is no data to export',
        err_quarter_or: 'Month or quarter must be filled in',
        err_quarter: 'quarter must be filled in',
        err_month: 'month must be filled in',
        enter: 'Please Input',
        err_down: 'Download Error Message Documentation',
        export_detail_tip: 'Settlement Period or RebateName must choose one when exporting',
        enter_vin: 'Please enter a VIN code',
    },
    tips: common.table.enUS.tips,
};
