<template>
    <n-space align="center" h-full>
        <n-dropdown :options="options" trigger="click" @select="handleSelect">
            <n-avatar color="grey" cursor-pointer flex relative size="small">
                <NIcon flex>
                    <i-carbon-user-avatar-filled />
                </NIcon>
            </n-avatar>
        </n-dropdown>
        <n-ellipsis style="max-width: 100px">
            {{ username }}
        </n-ellipsis>
    </n-space>
    <ChangePsw :show="showChangePsw" @update:close="showChangePsw = false" />
    <n-modal v-bind="events" v-model:show="dialogVisible">
        <DealerInfo />
    </n-modal>
</template>

<script lang="ts" setup>
import type { DropdownOption } from 'naive-ui';
import { NIcon } from 'naive-ui';
import ChangePsw from './ChangePsw/ChangePsw.vue';
import DealerInfo from './DealerInfo/dialog.vue';
import { useDialog } from './DealerInfo/dialogs';
import Logout from '~icons/carbon/logout';
import Settings from '~icons/carbon/settings';
import ruleDataQuality from '~icons/carbon/rule-data-quality';
import { useUserStore } from '~/stores/user';
import { useThemeditorVisible } from '~/composables/useThemeEditor';

enum MenuKeys {
    logout = 'logout',
    showThemeEditor = 'showThemeEditor',
    Settings = 'Settings',
    ChangePsw = 'ChangePsw',
    DealerInfo = 'DealerInfo',
}

const showChangePsw = ref(false);
const showDealerInfo = ref(true);
const { dialogVisible, open, events } = useDialog();
const { t } = useTypedI18n();
const userStore = useUserStore();
const { showThemeEditor, localFlagShowThemeEditor } = useThemeditorVisible();
const actionMap = new Map<string, () => void | Promise<void>>();

const username = computed(() => userStore.userInfo?.username);
const portalUsername = computed(() => userStore.userInfo?.portalUsername);
const Dept = computed(() => userStore.userInfo?.dept);
const roleKey = computed(() => userStore.userInfo?.roles && userStore.userInfo?.roles.length && userStore.userInfo?.roles[0]!.roleKey);

const options: DropdownOption[] = [
    // {
    //     label: () =>
    //         h('span', null, {
    //             default: () => t('button.change_password'),
    //         }),
    //     icon: () =>
    //         h(NIcon, null, {
    //             default: () => h(Settings),
    //         }),
    //     key: MenuKeys.ChangePsw,
    // },
    // {
    //     label: () =>
    //         h('span', null, {
    //             default: () => t('auth.logout'),
    //         }),
    //     icon: () =>
    //         h(NIcon, null, {
    //             default: () => h(Logout),
    //         }),
    //     key: MenuKeys.logout,
    // },
];

if (showThemeEditor) {
    // options.unshift({
    //     label: () =>
    //         h('span', null, {
    //             default: () => t('button.show_theme_editor'),
    //         }),
    //     icon: () =>
    //         h(NIcon, null, {
    //             default: () => h(SettingsView),
    //         }),
    //     key: MenuKeys.showThemeEditor,
    // });
}

actionMap.set(MenuKeys.logout, userStore.logout);
actionMap.set(MenuKeys.showThemeEditor, () => {
    localFlagShowThemeEditor.value = !localFlagShowThemeEditor.value;
});
actionMap.set(MenuKeys.ChangePsw, () => {
    showChangePsw.value = true;
});
actionMap.set(MenuKeys.DealerInfo, () => {
    open({}, t('button.info'), {
        state: 'info',
    });
});
onMounted(() => {
    console.log(options, portalUsername.value);
    // console.log(999876, userStore.userInfo);
    if (!portalUsername.value) {
        options.push({
            label: () =>
                h('span', null, {
                    default: () => t('button.change_password'),
                }),
            icon: () =>
                h(NIcon, null, {
                    default: () => h(Settings),
                }),
            key: MenuKeys.ChangePsw,
        });
    }
    if (Dept.value!.deptType === '10081005' && roleKey.value !== 'EU_DEALER') {
        options.push({
            label: () =>
                h('span', null, {
                    default: () => t('button.dealer_info'),
                }),
            icon: () =>
                h(NIcon, null, {
                    default: () => h(ruleDataQuality),
                }),
            key: MenuKeys.DealerInfo,
        });
    }
    options.push({
        label: () =>
            h('span', null, {
                default: () => t('auth.logout'),
            }),
        icon: () =>
            h(NIcon, null, {
                default: () => h(Logout),
            }),
        key: MenuKeys.logout,
    });
});
const handleSelect = (key: string, option: DropdownOption) => {
    const action = actionMap.get(key);
    action && action();
};
</script>

<style scoped></style>
